import { motion } from 'framer-motion';
import styled from 'styled-components';

import CSSVARIABLES from 'global/constants/css/variables';

const FloaterWrapper = styled.div`
  .floater,
  .orderCount,
  .requestCount {
    position: fixed;
    background-color: ${CSSVARIABLES.primaryBackground};
    color: ${CSSVARIABLES.primaryColor};
    box-shadow: -5px 2px 5px 1px ${CSSVARIABLES.primaryBackgroundDark};
    text-align: center;
    border-radius: 20px;
    height: 30px;
    padding: 10px 8px 1px 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
    right: 20px;
    font-weight: 500;
    text-decoration: none;
  }

  .floater {
    bottom: 90px;
    width: 30px;
    color: ${CSSVARIABLES.secondaryColor};
  }

  .orderCount {
    bottom: 160px;
    width: fit-content;
    font-size: 0.8em;
  }

  .requestCount {
    bottom: 210px;
    width: fit-content;
    font-size: 0.8em;
  }
`;

const NavbarWrapper = styled.div`
  .brand {
    font-size: 1em !important;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .burger {
    /* padding: 1em; */
    background-color: #fff;
    padding: 1em !important;
  }

  .parent {
    position: relative;
    .count {
      position: fixed;
      top: 1vh;
      right: 12;
      color: ${CSSVARIABLES.secondaryColor};
      font-weight: bold;
    }
    .icon__cart {
      font-size: 1.3em;
    }
  }
  font-size: 0.8em;

  span {
    cursor: pointer;
  }

  .navbar {
    a {
      padding: 0.5em;
    }
    .logout {
      padding: 0.5em 2em;
    }
  }

  @media (max-width: 768px) {
    .count {
      display: none;
    }
  }
`;

export { NavbarWrapper, FloaterWrapper };
