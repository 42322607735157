import { combineReducers } from '@reduxjs/toolkit';

import cartSlice from 'features/cartSlice';
import counterReducer from 'features/counter/counterSlice';
import orderSlice from 'features/orderSlice';
import userSlice from 'features/userSlice';

const rootReducer = combineReducers({ counter: counterReducer, user: userSlice.reducer, order: orderSlice.reducer, cart: cartSlice.reducer });

export default rootReducer;
