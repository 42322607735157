
function toLowerIfString(value: any) {
    if (typeof(value) === 'string')
        value = value?.toLowerCase();
    return value;
}

function isNullOrUndefined (value: string | null | undefined) {
    value = toLowerIfString(value);
    return value === null || value === "none" || value === undefined;
}

function isTrue (value: string | boolean | undefined) {
    value = toLowerIfString(value);
    return value === true || value === "true";
}

export {isNullOrUndefined, isTrue}
