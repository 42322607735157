import { motion } from 'framer-motion';
import styled from 'styled-components';

import CSSVARIABLES from 'global/constants/css/variables';

interface Props {
  show: boolean;
}

const SearchviewWrapper = styled.div<Props>`
  /* padding-top: 5vh; */
  text-align: center;
  margin-top: 0px;

  .search__container {
    width: 100vw;
    margin-top: 39px;
    position: fixed;
    background-color: ${CSSVARIABLES.primaryBackground};
    background-color: #bb3a3e;

    z-index: 10;
    /* background-color: blue; */
    /* left: 50%; */

    input {
      width: 30%;
      padding: 1em 1em;
      background-color: ${CSSVARIABLES.primaryBackgroundDark};
      border: none;
      font-family: ${CSSVARIABLES.primaryFontFamily};
      ::placeholder {
        /* color: #cec5c5; */
        font-family: ${CSSVARIABLES.primaryFontFamily};
        font-weight: 500;
        opacity: 0.5;
      }
    }

    button {
      margin: 0 auto;
      background-color: ${CSSVARIABLES.secondaryBackground2};
      color: ${CSSVARIABLES.primaryColor2};
      border: none;
      /* width: fit-content; */
      padding: 1em 2em !important;
      text-transform: uppercase;
      margin-top: 4vh;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .content {
    padding-top: 12vh;
    padding-bottom: 10vh;

    .error {
      background-color: ${CSSVARIABLES.dangerLight};
      color: ${CSSVARIABLES.dangerDark};
      padding: 1em;
      text-align: center;
    }
    .success {
      background-color: ${CSSVARIABLES.successLight};
      color: ${CSSVARIABLES.successDark};
      text-align: center;
      padding: 1em;
    }
    .green {
      color: ${CSSVARIABLES.successDark};
      font-weight: bold;
      font-size: 1.1em;
    }
    h1 {
      text-align: center;
      font-size: 1.8em;
    }
    .icon__back {
      cursor: pointer;
    }

    .aside {
      position: fixed;
      right: 6vw;
      top: 16%;
      /* border: 1px solid ${CSSVARIABLES.gray1}; */
      /* background-color: ${CSSVARIABLES.primaryBackground}; */
      /* padding: 2em; */

      .icon__close {
        cursor: pointer;
        position: absolute;
        left: 1vw;
        top: 1vh;
      }
      .mpopover {
      }
    }
    ${(props) =>
      props?.show &&
      `
        .aside {
            border: 1px solid ${CSSVARIABLES.gray1};
            background-color: ${CSSVARIABLES.primaryBackground};
            padding: 2em;
            z-index: 10;

            Button {
              margin-top: 4vh;
            }
        }
    `}

    .query {
      color: ${CSSVARIABLES.secondaryColor};
      text-transform: capitalize;
    }
    padding-bottom: 12vh;

    .wines {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 2em;
      width: 60%;
      margin: 0 auto;

      .wine {
        flex: 1;
        margin-top: 4vh;
        text-align: left;
        cursor: pointer;
        max-width: 215px;
        min-width: 215px;
        height: 294px;

        .wine-image {
          background-image: url(${CSSVARIABLES.wineBackgroundImage});
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 100%;
          height: 60%;
        }

        img {
          mix-blend-mode: multiply;
          display: block;
          margin: auto;
          height: 100%;
        }
        p {
          text-transform: capitalize;
          width: 80%;
        }
        .prices {
          display: flex;
          gap: 1em;
        }
        .price {
          /* padding: 1em; */
          color: ${CSSVARIABLES.secondaryColor};
          font-weight: 600;
        }
        .avg_price {
          text-decoration: line-through;
          color: ${CSSVARIABLES.gray1};
        }
      }
    }
  }
  @media (max-width: 768px) {
    .content {
      padding-top: 20vh;
      .aside {
        /* background-color: ${CSSVARIABLES.primaryBackground}; */
        width: 100%;
        top: auto;
        bottom: 0vh;
        right: auto;
        z-index: 10;
        padding: 0;

        .upload_image {
          .fileSelector {
            width: 21% !important;
          }
        }
        text-align: center;
      }
    }
    /* padding-top: 10vh; */

    h1 {
      font-size: 1em;
    }

    .wines {
      width: 90% !important;
      gap: 0.5em !important;

      .wine {
        margin-top: 3vh !important;
        margin-bottom: 3vh !important;
        max-width: 155px !important;
        min-width: 155px !important;
      }
    }
  }
`;

export { SearchviewWrapper };
