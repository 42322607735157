import { createGlobalStyle, css } from 'styled-components';

import CSSVARIABLES from 'global/constants/css/variables';

const globalStyle = createGlobalStyle`
${({ theme }) => css`
  body {
    /* box-sizing: border-box; */
    margin: 0;
    padding: 0;
    /* width: 100%; */
    height: fit-content;
    background: ${theme.backgroundColor};
    font-family: ${CSSVARIABLES.primaryFontFamily};
  }
  a {
    color: ${CSSVARIABLES.secondaryColor};
  }
`}
`;

export default globalStyle;
