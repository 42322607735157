import React, { Suspense, lazy } from 'react';

const LazyOrderSuccessPage = lazy(() => import('./OrderSuccessPage'));

const OrderSuccessPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyOrderSuccessPage {...props} />
  </Suspense>
);

export default OrderSuccessPage;
