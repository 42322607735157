import React, { Suspense, lazy } from 'react';

const LazyPersonalDealsPage = lazy(() => import('./PersonalDealsPage'));

const PersonalDealsPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyPersonalDealsPage {...props} />
  </Suspense>
);

export default PersonalDealsPage;
