import React, { Suspense, lazy } from 'react';

const LazyBuyerRequestsPage = lazy(() => import('./BuyerRequestsPage'));

const BuyerRequestsPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyBuyerRequestsPage {...props} />
  </Suspense>
);

export default BuyerRequestsPage;
