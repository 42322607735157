import React, { Suspense, lazy } from 'react';

const LazyPriceCheckPage = lazy(() => import('./PriceCheckPage'));

const PriceCheckPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyPriceCheckPage {...props} />
  </Suspense>
);

export default PriceCheckPage;
