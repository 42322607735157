const ROUTES = {
  HOME: '/',
  NOT_FOUND: '/404',
  PROFILE: '/profile',
  USER_SIGNUP: '/register',
  USER_LOGIN: '/login',
  USER_CUSTOM_REQUEST: '/custom-request',
  DISCOVER: '/discover',
  PRODUCT: '/p',
  CART: '/cart',
  ORDER_DETAILS: '/order/details',
  ORDER_SUCCESS: '/order/success',
  ORDER_SHIPPING_INFO: '/order/shipping-info',
  ORDER_PAYMENT_INFO: '/order/payment-info',
  ORDER_CONFIRMATION: '/order/confirm',
  BUYER_ORDERS: '/orders',
  SELLER_ORDERS: '/admin/orders',
  BUYER_REQUESTS: '/requests',
  ADMIN_SIGNUP: '/admin/register',
  ADMIN_LOGIN: '/admin/login',
  ADMIN_REQUESTS: '/admin/requests',
  ADMIN_DASHBOARD: '/admin/dashboard',
  VERIFY_OTP: '/otp/verify',
  ADMIN_VERIFY_OTP: '/admin/otp/verify',
  ADMIN_INVENTORY: '/admin/inventory',
  ADMIN_PROFILE: '/admin/profile',
  ADMIN_HOME: '/admin/home',
  FORGOT_PASSWORD: '/forgotpassword',
  SEARCH: '/search',
  DAILY_DEALS: '/deals/daily',
  PERSONAL_DEALS: '/deals/personal',
  BUYER_HOME: '/home',
  CHECKOUT: '/checkout',
  CUSTOMERS: '/customers'
};

export default ROUTES;
