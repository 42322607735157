import React, { useEffect, useState } from 'react';

import Navbar from '@acto/react-navbar';
import { faBell, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import { logoutUser } from 'features/userSlice';
import API from 'global/constants/api';
import CSSVARIABLES from 'global/constants/css/variables';
import ENDPOINTS from 'global/constants/endpoints';
import METHODS from 'global/constants/restMethods';
import ROUTES from 'global/constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { isTrue } from 'features/jsonHelper'
import { FloaterWrapper, NavbarWrapper } from './Navbar.styles';

declare interface INavbarProps {}

const navTheme = {
  mainColor: CSSVARIABLES.secondaryColor,
  backgroundColor: CSSVARIABLES.primaryBackground,
  height: '6vh',
  menuBgColor: CSSVARIABLES.primaryBackgroundDark,
};

const leftLinks = (
  <>
    {/* <a href='#'>Our Plans</a>
    <a href='#'>How It Works</a>
    <a href='#'>Our Menus</a> */}
  </>
);

// const rightLinks = (
//   <>
//     {/* <a href={ROUTES.ADMIN_HOME}>Home</a> */}
//     <a href={ROUTES.ADMIN_INVENTORY}>Inventory</a>
//     <a href={ROUTES.ADMIN_PROFILE}>Profile</a>
//   </>
// )

const CustomNavbar: React.FC = (props: INavbarProps) => {
  const profile = useAppSelector((state) => state.user.profile);
  const dispatch = useAppDispatch();
  const CART = useAppSelector((state) => state.cart);
  const token = useAppSelector((state) => state.user.token);
  const [ordersCount, setOrdersCount] = useState<number>(0);
  const [requestsCount, setRequestsCount] = useState<number>(0);

  async function getRequestsCount() {
    if (isTrue(profile?.is_admin)) {
      const response = await fetch(API + ENDPOINTS.SELLER_CUSTOM_REQUEST, {
        method: METHODS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = await response.json();
      const data = result.body.requests.filter((request: any) => {
        if (request.status == 'PLACED') {
          return request;
        }
      });
      setRequestsCount(data.length);
    } else {
      const response = await fetch(API + ENDPOINTS.BUYER_CUSTOM_REQUEST, {
        method: METHODS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.status === 401) {
        dispatch(logoutUser());
      }

      const result = await response.json();
      const data = result?.body?.requests?.filter((request: any) => {
        if (request.status == 'PLACED') {
          return request;
        }
      });
      setRequestsCount(data?.length);
    }
  }

  async function getOrdersCount() {
    if (isTrue(profile?.is_admin)) {
      const response = await fetch(API + ENDPOINTS.SELLER_ORDERS, {
        method: METHODS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      const data = result.body.orders.filter((order: any) => {
        if (order.status == 'PLACED') {
          return order;
        }
      });
      setOrdersCount(data.length);
    } else {
      const response = await fetch(API + ENDPOINTS.BUYER_ORDERS, {
        method: METHODS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 401) {
        dispatch(logoutUser());
      }
      const result = await response.json();
      const data = result?.body?.orders?.filter((order: any) => {
        if (order.status == 'PLACED') {
          return order;
        }
      });
      setOrdersCount(data?.length);
    }
  }

  useEffect(() => {
    getRequestsCount();
    getOrdersCount();
  }, []);

  const navBrandLink = (() => {
    if (isTrue(profile?.is_admin)) {
      return ROUTES.ADMIN_HOME;
    }
    return ROUTES.BUYER_HOME;
  })();

  const profileLink = (() => {
    if (isTrue(profile?.is_admin)) {
      return ROUTES.ADMIN_PROFILE;
    }
    return ROUTES.PROFILE;
  })();

  const requestsLink = (() => {
    if (isTrue(profile?.is_admin)) {
      return ROUTES.ADMIN_REQUESTS;
    }
    return ROUTES.BUYER_REQUESTS;
  })();

  const navBrand = (
    <a href={navBrandLink} className="brand">
      THE SOURCE
    </a>
  );

  const rightLinks = (() => {
    if (isTrue(profile?.is_admin)) {
      return (
        <>
          <a href={ROUTES.SELLER_ORDERS} className="parent">
            Orders <span className="count">{ordersCount}</span>
          </a>
          <a href={requestsLink} className="parent">
            Custom Requests <span className="count">{requestsCount}</span>
          </a>
          <a href={ROUTES.ADMIN_INVENTORY}>Inventory</a>
          <span className="logout" onClick={() => dispatch(logoutUser())}>
            Logout
          </span>
        </>
      );
    }
    return (
      <>
        {/* <Button text="Logout" color={CSSVARIABLES.primaryColor2} bgColor={CSSVARIABLES.secondaryBackground2} onClick={() => dispatch(logoutUser())} /> */}

        <a href={ROUTES.BUYER_ORDERS} className="parent">
          My Orders <span className="count">{ordersCount}</span>
        </a>
        <a href={requestsLink} className="parent">
          My Requests <span className="count">{requestsCount}</span>
        </a>
        <a href={ROUTES.CART} className="parent">
          <FontAwesomeIcon className="icon__cart" icon={faCartPlus} /> <span className="count">{CART.items.length}</span>
        </a>
        <span className="logout" onClick={() => dispatch(logoutUser())}>
          Logout
        </span>
      </>
    );
  })();

  const leftLinks = (() => {
    if (isTrue(profile?.is_admin)) {
      return (
        <>
          <a href={navBrandLink}>Home</a>
          <a href={profileLink}>Profile</a>
          <a href={ROUTES.CUSTOMERS}>Customers</a>
        </>
      );
    }
    return (
      <>
        <a href={navBrandLink}>Home</a>
        <a href={profileLink}>Profile</a>
        <a href={ROUTES.PERSONAL_DEALS}>Personal Deals</a>
      </>
    );
  })();

  return (
    <NavbarWrapper data-testid="Navbar">
      <Navbar
        className="navbar" // style .navbar in your css
        menuClassName="navbar--menu" // style .navbar--menu in your css
        brand={navBrand}
        theme={navTheme}
        leftLinks={leftLinks}
        rightLinks={rightLinks}
        shouldHideOnScroll={false}
      />
    </NavbarWrapper>
  );
};

export const Floater: React.FC = () => {
  const [ordersCount, setOrdersCount] = useState<number>(0);
  const [requestsCount, setRequestsCount] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const profile = useAppSelector((state) => state.user.profile);
  const dispatch = useAppDispatch();
  const CART = useAppSelector((state) => state.cart);
  const token = useAppSelector((state) => state.user.token);

  async function getRequestsCount() {
    if (isTrue(profile?.is_admin)) {
      const response = await fetch(API + ENDPOINTS.SELLER_CUSTOM_REQUEST, {
        method: METHODS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = await response.json();
      const data = result.body.requests.filter((request: any) => {
        if (request.status == 'PLACED') {
          return request;
        }
      });
      setRequestsCount(data.length);
    } else {
      const response = await fetch(API + ENDPOINTS.BUYER_CUSTOM_REQUEST, {
        method: METHODS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.status === 401) {
        dispatch(logoutUser());
      }

      const result = await response.json();
      const data = result?.body?.requests?.filter((request: any) => {
        if (request.status == 'PLACED') {
          return request;
        }
      });
      setRequestsCount(data?.length);
    }
  }

  async function getOrdersCount() {
    if (isTrue(profile?.is_admin)) {
      const response = await fetch(API + ENDPOINTS.SELLER_ORDERS, {
        method: METHODS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      const data = result.body.orders.filter((order: any) => {
        if (order.status == 'PLACED') {
          return order;
        }
      });
      setOrdersCount(data.length);
    } else {
      const response = await fetch(API + ENDPOINTS.BUYER_ORDERS, {
        method: METHODS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 401) {
        dispatch(logoutUser());
      }
      const result = await response.json();
      const data = result?.body?.orders?.filter((order: any) => {
        if (order.status == 'PLACED') {
          return order;
        }
      });
      setOrdersCount(data?.length);
    }
  }

  useEffect(() => {
    getRequestsCount();
    getOrdersCount();
  }, []);

  return (
    <FloaterWrapper>
      <div>
        {show && (
          <a className="requestCount" href={ROUTES.BUYER_REQUESTS}>
            Requests: {requestsCount}
          </a>
        )}
        {show && (
          <a className="orderCount" href={ROUTES.BUYER_ORDERS}>
            Orders: {ordersCount}
          </a>
        )}
        {/* {show && <p>Order: {ordersCount}</p>} */}
        <p className="floater" onClick={() => setShow(!show)}>
          <FontAwesomeIcon className="icon__plus" icon={faBell} />
        </p>
      </div>
    </FloaterWrapper>
  );
};

export default CustomNavbar;
