import React, { Suspense, lazy } from 'react';

const LazySellerRequestsPage = lazy(() => import('./SellerRequestsPage'));

const SellerRequestsPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazySellerRequestsPage {...props} />
  </Suspense>
);

export default SellerRequestsPage;
