import { motion } from 'framer-motion';
import styled from 'styled-components';

import CSSVARIABLES from 'global/constants/css/variables';
// import wineBg from 'assets/images/'

const DiscoverWrapper = styled.div`
  text-align: center;
  /* background-color: blue; */
  margin-top: 0px;
  /* background-color: blue; */
  .search__container {
    width: 100vw;
    margin-top: 39px;
    position: fixed;
    background-color: ${CSSVARIABLES.primaryBackground};
    background-color: #bb3a3e;

    z-index: 10;
    /* background-color: blue; */
    /* left: 50%; */
  }
  input {
    width: 30%;
    padding: 1em 1em;
    background-color: ${CSSVARIABLES.primaryBackgroundDark};
    border: none;
    font-family: ${CSSVARIABLES.primaryFontFamily};
    ::placeholder {
      /* color: #cec5c5; */
      font-family: ${CSSVARIABLES.primaryFontFamily};
      font-weight: 500;
      opacity: 0.5;
    }
  }

  button {
    margin: 0 auto;
    background-color: ${CSSVARIABLES.secondaryBackground2};
    color: ${CSSVARIABLES.primaryColor2};
    border: none;
    /* width: fit-content; */
    padding: 1em 2em !important;
    text-transform: uppercase;
    margin-top: 4vh;
    font-weight: 600;
    cursor: pointer;
  }

  .content {
    padding-top: 12vh;
    padding-bottom: 10vh;

    .category_deals,
    .daily_deals {
      margin-top: 7vh;
      margin-bottom: 8vh;

      h1 {
        font-size: 1.5em;
      }
    }
    .wines {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 2em;
      width: 60%;
      margin: 0 auto;

      .wine {
        flex: 1;
        margin-top: 4vh;
        text-align: left;
        cursor: pointer;
        max-width: 215px;
        min-width: 215px;
        height: 294px;

        .wine-image {
          background-image: url(${CSSVARIABLES.wineBackgroundImage});
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 100%;
          height: 60%;
        }

        img {
          mix-blend-mode: multiply;
          display: block;
          margin: auto;
          height: 100%;
        }
        p {
          text-transform: capitalize;
          width: 100%;
        }
        .prices {
          display: flex;
          gap: 1em;
        }
        .green {
          color: ${CSSVARIABLES.successDark};
          font-weight: bold;
          font-size: 1.1em;
        }
        .price {
          /* padding: 1em; */
          color: ${CSSVARIABLES.secondaryColor};
          font-weight: 600;
        }
        .avg_price {
          text-decoration: line-through;
          color: ${CSSVARIABLES.gray1};
        }
      }
    }
  }

  @media (max-width: 768px) {
    .content {
      padding-top: 20vh;
    }

    .wines {
      width: 90% !important;
      gap: 0.5em !important;

      .wine {
        margin-top: 1vh !important;
        margin-bottom: 1vh !important;
        max-width: 155px !important;
        min-width: 155px !important;
      }
    }
    /* .wine {
                margin: 0 auto;

                img {
                    max-width: 100%;
                }
            } */
    input,
    button {
      margin: 0 auto;
      display: block;
      width: 80% !important;
      margin-top: 2vh;
    }
  }
`;

export { DiscoverWrapper };
