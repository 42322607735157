import React from 'react';

import { Redirect, Route } from 'react-router';

import ROUTES from 'global/constants/routes';

import { isTrue } from 'features/jsonHelper'
import { SellerUnAuthenticatedRouteWrapper } from './SellerUnAuthenticatedRoute.styles';

declare interface IAuthenticatedRouteProps {
  component: React.FC;
  isAuthenticated: boolean;
  isAdmin: string;
  componentProps?: any;
  path: string;
}

const SellerUnAuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = (props: IAuthenticatedRouteProps) => {
  const C = props.component;
  const { isAuthenticated } = props;
  const { componentProps } = props;
  const { isAdmin } = props;

  return (
    <SellerUnAuthenticatedRouteWrapper data-testid="AuthenticatedRoute">
      <Route {...componentProps} render={() => (isAuthenticated && isTrue(isAdmin) ? <Redirect to={ROUTES.ADMIN_HOME} /> : <C {...props} {...componentProps} />)} />
    </SellerUnAuthenticatedRouteWrapper>
  );
};

export default SellerUnAuthenticatedRoute;
