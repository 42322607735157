import React, { Suspense, lazy } from 'react';

const LazyCartPage = lazy(() => import('./CartPage'));

const CartPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyCartPage {...props} />
  </Suspense>
);

export default CartPage;
