import React from 'react';

import { ButtonWrapper } from './Button.styles';

declare interface IButtonProps {
  text: string;
  color: string;
  bgColor: string;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  ref?: any;
  loading?: boolean;
  loadingText?: string;
}

const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  return (
    <ButtonWrapper bgColor={props.bgColor} color={props.color} disabled={props?.loading} onClick={props.onClick}>
      {props?.loading ? props.loadingText : props.text}
    </ButtonWrapper>
  );
};

export default Button;
