import React, { useEffect, useState } from 'react';

import { Redirect, useHistory } from 'react-router';

import DefaultView from 'components/DefaultView';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { Floater } from 'components/Navbar/Navbar';
import Searchview from 'components/Searchview';
import { logoutUser } from 'features/userSlice';
import API from 'global/constants/api';
import ENDPOINTS from 'global/constants/endpoints';
import METHODS from 'global/constants/restMethods';
import ROUTES from 'global/constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';

import { DiscoverWrapper } from './Discover.styles';

declare interface IDiscoverProps {}

const Discover: React.FC = (props: IDiscoverProps) => {
  const token = useAppSelector((state) => state.user.token);
  const [rows, setRows] = useState<any[]>([]);
  const [searchRows, setSearchRows] = useState<any[]>([]);
  const [query, setQuery] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [searchView, setSearchView] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function getRows() {
      const response = await fetch(API + ENDPOINTS.PRODUCTS, {
        method: METHODS.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response?.status === 401) {
        dispatch(logoutUser());
      }
      // console.log(data);

      setRows(data.body.products);
    }

    getRows();
    // setRows([])
  }, []);

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!query) return;
    history.push(`${ROUTES.SEARCH}/${query}`);
  };

  return (
    <DiscoverWrapper data-testid="Discover">
      <Navbar />
      <div className="search__container">
        <form onSubmit={(e) => search(e)}>
          <input type="text" placeholder="What are you looking for?" value={query} onChange={(e) => setQuery(e.target.value)} />
          <button type="submit">{searching ? 'Searching...' : 'Search'}</button>
        </form>
      </div>
      <section className="content">{searchView ? <Searchview query={query} rows={searchRows} loadMoreItems={null} noMoreItems={null} /> : <DefaultView rows={rows} />}</section>
      <Floater />
      <Footer />
    </DiscoverWrapper>
  );
};

export default Discover;
