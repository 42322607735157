import React, { useEffect, useRef, useState } from 'react';

import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';

import Button from 'components/Button';
import UploadImage from 'components/UploadImage';
import { logoutUser } from 'features/userSlice';
import API from 'global/constants/api';
import CSSVARIABLES from 'global/constants/css/variables';
import ENDPOINTS from 'global/constants/endpoints';
import METHODS from 'global/constants/restMethods';
import ROUTES from 'global/constants/routes';
import { useAppSelector } from 'hooks/storeHooks';
import { isNullOrUndefined } from 'features/jsonHelper';
import { SearchviewWrapper } from './Searchview.styles';

declare interface ISearchviewProps {
  rows: any[];
  query: string;
  loadMoreItems: any;
  noMoreItems: any;
}

const Searchview: React.FC<ISearchviewProps> = (props: ISearchviewProps) => {
  const history = useHistory();
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [name, setName] = useState<string>(props?.query);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const token = useAppSelector((state) => state.user.token);
  const [imgUrl, setImgUrl] = useState<string>('');
  const [SellerId, setSellerId] = useState<string>('');
  const [Error, setError] = useState<string>('');
  const [sellers, setSellers] = useState<any[]>([]);
  const [query, setQuery] = useState<string>(props?.query);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchRows, setSearchRows] = useState<any[]>(props?.rows);

  const submit = async () => {
    setError('');
    setSuccess('');

    // if (!SellerId) {
    //   setError('Select Seller')
    //   return
    // }
    if (!name) {
      setError('Enter Wine Name');
      return;
    }
    setLoading(true);
    const response = await fetch(API + ENDPOINTS.CUSTOM_REQUEST, {
      method: METHODS.POST,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name, request_img_url: imgUrl, seller_id: 20 }),
    });
    setLoading(false);
    setSuccess('Request placed');
    setShow(false);
  };

  return (
    <SearchviewWrapper data-testid="Searchview" show={show}>
      <div className="search__container">
        <form>
          <input type="text" placeholder="What are you looking for?" value={query} onChange={(e) => setQuery(e.target.value)} />
          <button
            onClick={() => {
              history.push(`${ROUTES.SEARCH}/${query}`);
            }}
          >
            {searching ? 'Searching...' : 'Search'}
          </button>
        </form>
      </div>
      <section className="content">
        {success && <p className="success">{success}!</p>}
        {Error && <p className="error">{Error}!</p>}
        {/* <a href={ROUTES.USER_CUSTOM_REQUEST}> */}
        {/* </a> */}
        <div className="aside">
          {/* <Overlay target={target.current} placement="bottom" show={show}> */}
          {show ? (
            <p className="mpopover">
              <FontAwesomeIcon onClick={() => setShow(false)} className="icon__close" icon={faTimes} />
              <h4>Upload Image (Optional)</h4>
              <UploadImage setImgUrl={setImgUrl} dir="custom-requests" setSuccess={setSuccess} setError={setError} />
              <Button onClick={submit} text="Send custom request" loading={loading} loadingText="Requesting..." color={CSSVARIABLES.primaryColor2} bgColor={CSSVARIABLES.secondaryBackground} />
            </p>
          ) : (
            <Button ref={target} onClick={() => setShow(!show)} text="Send custom request" color={CSSVARIABLES.primaryColor2} bgColor={CSSVARIABLES.secondaryBackground} />
          )}
        </div>
        {/* </Overlay> */}
        {searchRows?.length ? (
          <section className="search_result">
            <h1>
              Search results for <span className="query">{props?.query}</span>{' '}
            </h1>
            <section className="wines">
              {searchRows.map((row) => (
                <div className="wine" key={row.id} onClick={() => history.push(`${ROUTES.PRODUCT}/${row.id}`)}>
                  <div className="wine-image">
                    {row?.img_url && !isNullOrUndefined(row?.img_url) ? <img src={row?.img_url} alt={row.name} /> : <img src="https://www.hhandc.co.uk/wp-content/uploads/no_image_2-300x300.png" />}
                  </div>
                  <p>{row.name}</p>
                  <div className="prices">
                    <span className="price">${parseFloat(row.winesource_price).toFixed(2)}</span>
                    <span className="avg_price">${parseFloat(row.average_price).toFixed(2)}</span>
                  </div>
                  {row.average_price > 0 && row.average_price - row.winesource_price > 0 ? (
                    <span>
                      <small>
                        Save <span className="green">${(row.average_price - row.winesource_price).toFixed(2)}</span> (
                        <span className="green">{(((row.average_price - row.winesource_price) * 100) / row.average_price).toFixed(2)}%</span>)
                      </small>
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </section>
            <br />
            {props.loadMoreItems && !props?.noMoreItems ? <Button text="Load more wines" color={CSSVARIABLES.primaryColor2} bgColor={CSSVARIABLES.secondaryBackground} onClick={props.loadMoreItems} /> : ''}
          </section>
        ) : (
          <section>
            <h1>
              <FontAwesomeIcon className="icon__back" icon={faArrowLeft} onClick={() => history.push('/home')} /> No results found for {props.query}
            </h1>
            {/* <a href={ROUTES.USER_CUSTOM_REQUEST}>Send a Custom Request</a> */}
          </section>
        )}
      </section>
    </SearchviewWrapper>
  );
};

export default Searchview;
