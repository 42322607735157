import React, { useState } from 'react';

import { useHistory } from 'react-router';

import API from 'global/constants/api';
import ENDPOINTS from 'global/constants/endpoints';
import METHODS from 'global/constants/restMethods';
import ROUTES from 'global/constants/routes';

import { ForgotPasswordWrapper } from './ForgotPassword.styles';

declare interface IForgotPasswordProps {}

const ForgotPassword: React.FC = (props: IForgotPasswordProps) => {
  const [Input, setInput] = useState<string>('');
  const [Confirm, setConfirm] = useState<string>('');
  const [Email, setEmail] = useState<string>('');
  const [OTP, setOTP] = useState<string>('');
  const [EmailSet, setEmailSet] = useState<boolean>(false);
  const [CodeVerified, setCodeVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [SendingCode, setSendingCode] = useState<boolean>(false);
  const [VerifyingCode, setVerifyingCode] = useState<boolean>(false);
  const [Error, setCustomError] = useState('');

  const history = useHistory();

  const reset = async () => {
    setCustomError('');

    if (!Input) {
      setCustomError('Enter Password');
      return;
    }
    if (!Confirm) {
      setCustomError('Confirm Password');
      return;
    }

    if (Input !== Confirm) {
      setCustomError("Passwords don't match!");
      return;
    }

    setLoading(true);
    const response = await fetch(API + ENDPOINTS.RESET_PASSWORD, {
      method: METHODS.POST,
      body: JSON.stringify({ email: Email, new_password: Input, code: OTP }),
    });

    const data = await response.json();

    if (data.status === 'SUCCESS') {
      history.push(ROUTES.USER_LOGIN);
    } else {
      setCustomError('Something went wrong. Try Again!');
    }
    setLoading(false);
  };

  const send_code = async () => {
    setCustomError('');
    setSendingCode(true);

    const response = await fetch(API + ENDPOINTS.SEND_RESET_PASSWORD_CODE, {
      method: METHODS.POST,
      body: JSON.stringify({ email: Email }),
    });

    const data = await response.json();

    if (data?.status === 'SUCCESS') {
      setEmailSet(true);
    } else {
      setCustomError('Something went wrong. Try Again!');
    }
    setSendingCode(false);
  };

  const verify_code = async () => {
    setCustomError('');
    setVerifyingCode(true);

    const response = await fetch(API + ENDPOINTS.VERIFY_RESET_CODE, {
      method: METHODS.POST,
      body: JSON.stringify({ email: Email, code: OTP }),
    });

    const data = await response.json();

    if (data?.status === 'SUCCESS') {
      setCodeVerified(true);
    } else {
      setCustomError('Something went wrong. Try Again!');
    }
    setVerifyingCode(false);
  };

  return (
    <ForgotPasswordWrapper data-testid="ForgotPassword">
      <header>
        <h3 className="brand">the source</h3>
        <a href={ROUTES.USER_LOGIN}>
          <button className="login_btn">log in</button>
        </a>
      </header>
      <div className="form__wrapper">
        <section>
          {EmailSet ? (
            <>
              {!CodeVerified ? (
                <section>
                  <h2>Enter OTP</h2>
                  {Error && <div className="error">{Error}</div>}
                  <br />
                  <p>Enter OTP sent to your E-mail</p>
                  <input required type="text" placeholder="1234" value={OTP} onChange={(e) => setOTP(e.target.value)} />
                  <button
                    onClick={() => {
                      if (!OTP) {
                        setCustomError('OTP is required');
                        return;
                      }
                      verify_code();
                    }}
                  >
                    {VerifyingCode ? 'Verifying...' : 'Verify Code'}
                  </button>
                  <p
                    className="resendCode"
                    onClick={() => {
                      if (!Email) {
                        setEmailSet(false);
                        setCustomError('Enter E-mail');
                        return;
                      }
                      send_code();
                    }}
                  >
                    Resend Code
                  </p>
                </section>
              ) : (
                <section>
                  <h2>Reset Password</h2>
                  {Error && <div className="error">{Error}</div>}
                  <br />
                  <input type="password" name="password" id="password" onChange={(e) => setInput(e.target.value)} value={Input} placeholder="Password" required />
                  <input type="password" name="confirm_password" id="confirm_password" onChange={(e) => setConfirm(e.target.value)} value={Confirm} placeholder="Confirm Password" required />
                  <button onClick={reset}>{!loading ? 'Reset' : 'loading...'}</button>
                  <br />
                  {/* <button onClick={() => setEmailSet(false)}>Back</button> */}
                </section>
              )}
            </>
          ) : (
            <section>
              <h2>Forgot Password</h2>
              {Error && <div className="error">{Error}</div>}
              <br />
              <p>Enter E-mail</p>
              <input required type="email" placeholder="example@email.com" value={Email} onChange={(e) => setEmail(e.target.value)} />
              <button
                onClick={() => {
                  if (!Email) {
                    setCustomError('Email is required');
                    return;
                  }
                  send_code();
                }}
              >
                {SendingCode ? 'Sending...' : 'Send Code'}
              </button>
            </section>
          )}
        </section>
      </div>
      <div className="banner">
        <h1>Welcome to TheSource</h1>
        <p>
          TheSource is a virtual wine list where you can find your favorite top quality wines at the best prices, guaranteed. Known as the “secret source” for accessing super high quality, difficult
          to get, expensive wines at crazy prices, TheSource is now available to exclusive invite only customers.
        </p>
        <p>
          TheSource was founded by one of the partners, who at the time owned restaurants with the license that allowed sales to off premise customers. Having the ability to buy product and “hot”
          deals from the distributors at substantially better prices, it soon attracted the wine aficionado’s and sports celebrities to this wine source. Popular as well, are the Daily Deals, which
          are time sensitive offers that typically sell out within 48 hours, so grab them before they’re gone.
        </p>
        <p>Happy shopping.</p>
        <p>Cheers!</p>
        <p>
          Brian Pierce <br />
          Founder and fan of Big, Soft Reds!
        </p>
      </div>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;
