const CSSVARIABLES = {
  primaryColor: '#000',
  primaryColor2: '#fff',
  primaryBackground: '#fff',
  primaryBackgroundDark: '#eff4fb',
  secondaryColor: '#890004',
  secondaryBackground: '#890004',
  secondaryBackground2: '#000',
  primaryFontFamily: 'Montserrat',
  gray1: '#696969',
  gray2: '#999',
  gray3: '#eee',
  successLight: '#d4edda',
  successDark: '#155724',
  infoLight: '#d1ecf1',
  dangerLight: '#f8d7da',
  dangerDark: '#721c24',
  infoDark: '#0c5460',
  wineBackgroundImage: 'https://www.color-name.com/color-image?c=F8F0E3&desktop'
};

export default CSSVARIABLES;
