import React, { Suspense, lazy } from 'react';

const LazyDailyDealsPage = lazy(() => import('./DailyDealsPage'));

const DailyDealsPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyDailyDealsPage {...props} />
  </Suspense>
);

export default DailyDealsPage;
