import React from 'react';

import { Redirect, Route } from 'react-router';

import ROUTES from 'global/constants/routes';

import { isTrue } from 'features/jsonHelper'
import { AuthenticatedRouteWrapper } from './SellerAuthenticatedRoute.styles';

declare interface IAuthenticatedRouteProps {
  component: React.FC;
  isAuthenticated: boolean;
  isAdmin: string;
  componentProps?: any;
  path: string;
}

const SellerAuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = (props: IAuthenticatedRouteProps) => {
  const C = props.component;
  const { isAuthenticated } = props;
  const { componentProps } = props;
  const { isAdmin } = props;

  return (
    <AuthenticatedRouteWrapper data-testid="AuthenticatedRoute">
      <Route {...componentProps} render={() => (isAuthenticated && isTrue(isAdmin) ? <C {...props} {...componentProps} /> : <Redirect to={ROUTES.ADMIN_LOGIN} />)} />
    </AuthenticatedRouteWrapper>
  );
};

export default SellerAuthenticatedRoute;
