import React, { Suspense, lazy } from 'react';

const LazyWineSearch = lazy(() => import('./WineSearch'));

const WineSearch = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyWineSearch {...props} />
  </Suspense>
);

export default WineSearch;
