import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Redirect, Route } from 'react-router';

import ROUTES from 'global/constants/routes';

import { AuthenticatedRouteWrapper } from './BuyerAuthenticatedRoute.styles';

declare interface IAuthenticatedRouteProps {
  component: React.FC;
  isAuthenticated: boolean;
  componentProps?: any;
  path: string;
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = (() => {
  if (process.env.REACT_APP_LIVE) {
    return loadStripe('pk_live_V8bR8QNnV1MayCs9QgiG3LN500yQgGjZpo');
  }
  return loadStripe('pk_test_DzbWHl2fGeClUVg85NDgv5kg00SlK12vJK');
})();

const BuyerAuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = (props: IAuthenticatedRouteProps) => {
  const C = props.component;
  const { isAuthenticated } = props;
  const { componentProps } = props;

  return (
    <AuthenticatedRouteWrapper data-testid="AuthenticatedRoute">
      <Route
        {...componentProps}
        render={() =>
          isAuthenticated ? (
            <Elements stripe={stripePromise}>
              <C {...props} {...componentProps} />
            </Elements>
          ) : (
            <Redirect to={ROUTES.USER_LOGIN} />
          )
        }
      />
    </AuthenticatedRouteWrapper>
  );
};

export default BuyerAuthenticatedRoute;
