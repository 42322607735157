import React, { Suspense, lazy } from 'react';

const LazyCustomRequestPage = lazy(() => import('./CustomRequestPage'));

const CustomRequestPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyCustomRequestPage {...props} />
  </Suspense>
);

export default CustomRequestPage;
