import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import BuyerAuthenticatedRoute from 'components/BuyerAuthenticatedRoute';
import BuyerUnAuthenticatedRoute from 'components/BuyerUnAuthenticatedRoute';
import Discover from 'components/Discover';
import ForgotPassword from 'components/ForgotPassword';
import SellerAuthenticatedRoute from 'components/SellerAuthenticatedRoute';
import SellerUnAuthenticatedRoute from 'components/SellerUnAuthenticatedRoute';
import ROUTES from 'global/constants/routes';
import { useAppSelector } from 'hooks/storeHooks';
import usePageViews from 'hooks/usePageViews';
import Discovery from 'pages/Buyer/Discover/Discover.lazy';
import Signin from 'pages/Buyer/Signin/Signin.lazy';
import Register from 'pages/Buyer/Signup/Register.lazy';
import BuyerOrdersPage from 'pages/BuyerOrdersPage/BuyerOrdersPage.lazy';
import BuyerRequestsPage from 'pages/BuyerRequestsPage/BuyerRequestsPage.lazy';
import CartPage from 'pages/CartPage/CartPage.lazy';
import CategoryPage from 'pages/CategoryPage/CategoryPage.lazy';
import CheckoutPage from 'pages/CheckoutPage/CheckoutPage.lazy';
import Counter from 'pages/counter';
import CustomRequestPage from 'pages/CustomRequestPage/CustomRequestPage.lazy';
import DailyDealsPage from 'pages/DailyDealsPage/DailyDealsPage.lazy';
import Home from 'pages/Home/Home.lazy';
import Inventory from 'pages/Inventory/Inventory.lazy';
import NotFoundPage from 'pages/NotFound/NotFound.lazy';
import OrderSuccessPage from 'pages/OrderSuccessPage/OrderSuccessPage.lazy';
import Otp from 'pages/Otp/Otp.lazy';
import PersonalDealsPage from 'pages/PersonalDealsPage/PersonalDealsPage.lazy';
import PriceCheckPage from 'pages/PriceCheckPage/PriceCheckPage.lazy';
import ProductPage from 'pages/ProductPage/ProductPage.lazy';
import ProfilePage from 'pages/ProfilePage/ProfilePage.lazy';
import Dashboard from 'pages/Seller/Dashboard/Dashboard.lazy';
import AdminLogin from 'pages/Seller/Signin/Signin.lazy';
import AdminSignup from 'pages/Seller/Signup/Register.lazy';
import SellerOrdersPage from 'pages/SellerOrdersPage/SellerOrdersPage.lazy';
import SellerRequestsPage from 'pages/SellerRequestsPage/SellerRequestsPage.lazy';
import WineSearch from 'pages/WineSearch/WineSearch.lazy';
import CustomersPage from 'pages/CustomersPage/CustomersPage.lazy';

const AppRouterSwitch: React.FC = () => {
  const loggedin = useAppSelector((state) => state.user.loggedin);
  const isAdmin = useAppSelector((state) => state.user?.profile?.is_admin);

  usePageViews();
  return (
    <div>
      <Switch>
        <Route exact path="/counter" component={Counter} />
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={ROUTES.NOT_FOUND} component={NotFoundPage} />

        <BuyerUnAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.USER_SIGNUP} component={Register} />
        <BuyerUnAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.USER_LOGIN} component={Signin} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.VERIFY_OTP} component={Otp} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.SEARCH} component={WineSearch} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.BUYER_HOME} component={Discovery} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.DISCOVER} component={Discover} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.DAILY_DEALS} component={DailyDealsPage} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.PERSONAL_DEALS} component={PersonalDealsPage} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.PROFILE} component={ProfilePage} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.USER_CUSTOM_REQUEST} component={CustomRequestPage} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.BUYER_REQUESTS} component={BuyerRequestsPage} />
        {/* <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.ORDER_DETAILS} component={OrderDetailsPage} /> */}
        {/* <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.ORDER_CONFIRMATION} component={OrderConfirmPage} /> */}
        {/* <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.ORDER_SHIPPING_INFO} component={OrderShippingInfoPage} /> */}
        {/* <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.ORDER_PAYMENT_INFO} component={OrderPaymentInfo} /> */}
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.ORDER_SUCCESS} component={OrderSuccessPage} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.BUYER_ORDERS} component={BuyerOrdersPage} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.CART} component={CartPage} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path={ROUTES.CHECKOUT} component={CheckoutPage} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path="/p/:id" component={ProductPage} />
        <BuyerAuthenticatedRoute isAuthenticated={loggedin} path="/c/:name/:id" component={CategoryPage} />

        <SellerUnAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path={ROUTES.ADMIN_SIGNUP} component={AdminSignup} />
        <SellerUnAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path={ROUTES.ADMIN_LOGIN} component={AdminLogin} />
        <SellerAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path={ROUTES.ADMIN_VERIFY_OTP} component={Otp} />
        <SellerAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path={ROUTES.ADMIN_DASHBOARD} component={Dashboard} />
        <SellerAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path={ROUTES.ADMIN_INVENTORY} component={Inventory} />
        <SellerAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path={ROUTES.ADMIN_PROFILE} component={ProfilePage} />
        <SellerAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path={ROUTES.ADMIN_HOME} component={PriceCheckPage} />
        <SellerAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path={ROUTES.ADMIN_REQUESTS} component={SellerRequestsPage} />
        <SellerAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path={ROUTES.SELLER_ORDERS} component={SellerOrdersPage} />
        <SellerAuthenticatedRoute isAuthenticated={loggedin} isAdmin={isAdmin} path="/customers" component={CustomersPage} />

        <Redirect to={ROUTES.NOT_FOUND} />
      </Switch>
    </div>
  );
};

export default AppRouterSwitch;
