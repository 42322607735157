import React, { Suspense, lazy } from 'react';

const LazyBuyerOrdersPage = lazy(() => import('./BuyerOrdersPage'));

const BuyerOrdersPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyBuyerOrdersPage {...props} />
  </Suspense>
);

export default BuyerOrdersPage;
