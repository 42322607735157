import React, { Suspense, lazy } from 'react';

const LazySellerOrdersPage = lazy(() => import('./SellerOrdersPage'));

const SellerOrdersPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazySellerOrdersPage {...props} />
  </Suspense>
);

export default SellerOrdersPage;
