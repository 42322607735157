import React from 'react';

import { Redirect, Route } from 'react-router';

import ROUTES from 'global/constants/routes';

import { BuyerUnAuthenticatedRouteWrapper } from './BuyerUnAuthenticatedRoute.styles';

declare interface IAuthenticatedRouteProps {
  component: React.FC;
  isAuthenticated: boolean;
  componentProps?: any;
  path: string;
}

const BuyerUnAuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = (props: IAuthenticatedRouteProps) => {
  const C = props.component;
  const { isAuthenticated } = props;
  const { componentProps } = props;

  return (
    <BuyerUnAuthenticatedRouteWrapper data-testid="AuthenticatedRoute">
      <Route {...componentProps} render={() => (isAuthenticated ? <Redirect to={ROUTES.BUYER_HOME} /> : <C {...props} {...componentProps} />)} />
    </BuyerUnAuthenticatedRouteWrapper>
  );
};

export default BuyerUnAuthenticatedRoute;
